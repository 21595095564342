@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


body {
  font-family: "Manrope", "Inter Display", sans-serif, "Montserrat", monospace,
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 104%;
}

@media (max-width: 380px) {
  html {
    font-size: 98%;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 94%;
  }
}

@media (max-width: 280px) {
  html {
    font-size: 5vw;
    font-size: calc(70% + 0.5vw);
  }
}

/*-------------| Inter Display |------------- */

@font-face {
  font-family: "Inter Display";
  src: url("../../../public/assets/fonts/inter-display/InterDisplay-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../../../public/assets/fonts/inter-display/InterDisplay-ExtraLight.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../../../public/assets/fonts/inter-display/InterDisplay-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../../../public/assets/fonts/inter-display/InterDisplay-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../../../public/assets/fonts/inter-display/InterDisplay-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../../../public/assets/fonts/inter-display/InterDisplay-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../../../public/assets/fonts/inter-display/InterDisplay-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../../../public/assets/fonts/inter-display/InterDisplay-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../../../public/assets/fonts/inter-display/InterDisplay-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

/* InterDisplay-Thin.woff2
InterDisplay-ExtraLight.woff2
InterDisplay-Light.woff2
InterDisplay-Medium.woff2
InterDisplay-Regular.woff2
InterDisplay-SemiBold.woff2
InterDisplay-Bold.woff2
InterDisplay-ExtraBold.woff2
InterDisplay-Black.woff2 */